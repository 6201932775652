const STATICS = {
  todoPadX: 10,
  todoPadY: 10,
  todoWidth: 210,
  todoHeight: 210, //! should be a multiple of 30 i.e the distance between lines in the background 
  texts: [
    'wash laundry',
    'do the dishes',
    'get milk',
    'get some aaa batteries for the television remote control',
    'the that thing I was doing'
    // Add more strings as needed
  ]
}

export default STATICS